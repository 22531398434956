import { css } from '@emotion/react';
import { useRouterPush } from 'components/configurable-routing';
import { Box, Flex } from 'components/box';
import Button from 'components/button';
import HomeIcon from 'shared/assets/icons/home.svg';
import RefreshIcon from 'shared/assets/icons/refresh.svg';
import BackIcon from 'shared/assets/icons/back-caret.svg';
import { useState, ReactElement } from 'react';
import { zIndices } from 'constants/z-indices';

export const NAVIGATION_BAR_HEIGHT_MOBILE = 42;
export const NAVIGATION_BAR_HEIGHT_TABLET = 58;

const NavigationButton = ({
  callback,
  children,
  disabled,
}: {
  callback: () => void;
  children: ReactElement;
  disabled?: boolean;
}) => (
  <Button
    variant="flat"
    color="darkGrey"
    fontWeight="normal"
    py="2"
    px="4"
    disabled={disabled}
    onClick={() => callback()}
  >
    {children}
  </Button>
);

const NavigationBar = () => {
  const push = useRouterPush();
  const [backDisabled, setBackDisabled] = useState(false);

  return (
    <Box
      py={[0, 2]}
      px={[0, 3]}
      css={css`
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: ${zIndices.NAV_BAR};
        background: #fff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      `}
    >
      <Flex
        px="4"
        m="0 auto"
        justifyContent="space-between"
        css={theme => css`
          max-width: ${theme.maxGridWidth}px;
        `}
      >
        <NavigationButton callback={() => push({ url: '/' })}>
          <HomeIcon
            css={css`
              fill: #828282;
            `}
          />
        </NavigationButton>

        <NavigationButton callback={() => location.reload()}>
          <RefreshIcon
            css={css`
              fill: #828282;
            `}
          />
        </NavigationButton>

        <NavigationButton
          disabled={backDisabled}
          callback={() => {
            setBackDisabled(true);
            history.back();
          }}
        >
          <BackIcon
            height={15}
            css={css`
              fill: #828282;
            `}
          />
        </NavigationButton>
      </Flex>
    </Box>
  );
};

export default NavigationBar;
