import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { css, Theme } from '@emotion/react';
import { StickyMessageInterface } from 'types/types';
import RichText from 'components/rich-text';
import Button from 'components/button';
import { X as XIcon } from 'react-feather';
import { Box } from 'components/box';
import { dismissMessagePopup } from '../../redux/global/actions';
import { SectionGroupEnum } from 'types/types';
import { zIndices } from 'constants/z-indices';

const defaultStyle = (theme: Theme) => css`
  margin: 0;
  padding: 5px 0;
  margin-right: ${theme.space[3]}px;
  z-index: ${zIndices.STICKY_DISMISSAL_TOAST_MESSAGE};
  text-align: center;
  color: ${theme.colors.information};
  a {
    color: ${theme.colors.link};
    text-decoration: underline;
    font-weight: 600;
  }
`;

const checkoutStyle = (theme: Theme) => css`
  margin: 0;
  padding: 5px 0;
  z-index: ${zIndices.STICKY_DISMISSAL_TOAST_MESSAGE};
  text-align: center;
  color: ${theme.colors.warning};
  a {
    color: ${theme.colors.link};
    text-decoration: underline;
    font-weight: 600;
  }
`;

const highPriorityStickyStyle = (theme: Theme) => css`
  width: 100%;
  margin: 0;
  padding: 15px;
  z-index: ${zIndices.STICKY_DISMISSAL_TOAST_MESSAGE};
  background: ${theme.colors.lightPink};
  text-align: center;
  border-bottom-color: ${theme.colors.danger};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: ${theme.colors.danger};
  a {
    color: ${theme.colors.danger};
    text-decoration: underline;
    font-weight: 600;
  }
`;

let style = defaultStyle;

const groupMessagesBySection = (messages: StickyMessageInterface[]) => {
  return messages.reduce((array, message) => {
    array[message.section] = array[message.section] || [];
    array[message.section].push(message);
    return array;
  }, []);
};

const mapToSection = (path: string) => {
  style = defaultStyle;
  if (path === '/') {
    return SectionGroupEnum.Home;
  } else if (path.includes('/product')) {
    return SectionGroupEnum.Product;
  } else if (path.includes('/checkout')) {
    style = checkoutStyle;
    return SectionGroupEnum.Checkout;
  } else if (path.includes('/my-account')) {
    return SectionGroupEnum.MyAccount;
  } else if (path.includes('/my-orders')) {
    return SectionGroupEnum.MyOrders;
  }
  return '';
};

export const getSectionMessage = (
  messages: StickyMessageInterface[],
  path: string
) => {
  const groupedMessages = groupMessagesBySection(messages);
  const sectionMessages = groupedMessages[mapToSection(path)];
  if (!sectionMessages) {
    return null;
  }
  return sectionMessages;
};

const StickyMessage = ({
  messages = [],
  path,
  isDismissible,
}: {
  messages?: StickyMessageInterface[];
  path: string;
  isDismissible?: boolean;
}) => {
  const dispatch = useDispatch();
  const currentMessages = getSectionMessage(messages, path);
  const dismissedMessagesBySection = useSelector(
    (state: RootState) => state.global.dismissedMessagesBySection
  );
  if (!currentMessages || !currentMessages.length) {
    return null;
  }

  return (
    <>
      {currentMessages.map((message, key) => {
        const color =
          message.section === SectionGroupEnum.Checkout
            ? 'lightOrange'
            : 'lightBlue';
        if (dismissedMessagesBySection === message.section) {
          return null;
        }
        return (
          <Box
            mx="auto"
            px="2"
            key={key}
            css={theme => css`
              ${isDismissible &&
              css`
                display: flex;
                justify-content: center;
                max-width: ${theme.maxHeaderWidth}px;
                position: relative;
              `}
            `}
          >
            <RichText
              fontSize={['0.75rem', 'r']}
              content={message.content}
              css={style}
            />
            {isDismissible && (
              <Button
                onClick={() => dispatch(dismissMessagePopup(message.section))}
                p="0"
                m="0"
                variant="flat"
                color={color}
                css={theme => css`
                  position: absolute;
                  top: 5px;
                  right: ${theme.space[2]}px;
                `}
              >
                <XIcon
                  css={theme => css`
                    color: ${theme.colors.primary};
                  `}
                  size={17}
                />
              </Button>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default StickyMessage;
